<!--
 * @Author: your name
 * @Date: 2022-03-14 10:41:43
 * @LastEditTime: 2022-04-14 09:58:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \wifivue\src\views\service\index.vue
-->
<template>
  <div class="service">
    <div class="header">
      <div class="head-box">
        <div class="head-tit">已为您分派客服</div>
        <div class="head-txt">7x8小时在线解答</div>
      </div>
      <div class="center">
        <img :src="info.wechat_img" alt="" class="service-code">
      </div>
    </div>
  </div>
</template>
<script>

export default ({
  data() {
    return {
     
      info:{}
    }
  },
  mounted(){
    this.getUrl()
  },
  methods:{
    // 获取企业客服微信
    getUrl(){
      this.getWechatQRcode().then(res =>{
        res.data.code == 1? this.info = res.data.info:this.$toast(`${res.data.msg}`)
      })
    },
    showpopup(){
      this.show = !this.show
    },
    // 反馈按钮
    feedback(){
      if(this.phone ==""){
        this.$toast("请您输入手机号")
        return false
      }
      if(this.message == ""){
        this.$toast("请您输入遇到的问题")
        return false
      }
      let _data = {
        iccid:this.$route.query.iccid,
        phone:this.phone,
        desc:this.message
      }
      this.subFeedBack({data:this.qs.stringify(_data)}).then(res =>{
        if(res.data.code == 1 ){
          this.$toast("提交成功,稍后会有客服和您联系")
        }
      })
    }
  }
})
</script>
<style lang="less" scoped>
.service{
  background-color: #f7f7f7;
  min-height: 100vh;
  .header{
    background-image: url('~@/assets/images/service/serviceBg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 0 20px 70px;
    .head-box {
    height: 180px;
    padding-top: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    color: #fff;
    .head-tit {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    .head-txt {
      font-size: 14px;
      }
    }
  }
  .center{
    min-height: 320px;
    margin-top: 20px;
    box-shadow: 0 5px 10px 0 #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    .service-code{
      width:100%;
      display: block;
    }
  }
}
</style>